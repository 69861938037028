body {
  font-family: 'Open Sans', sans-serif;
  box-sizing: border-box;
}

h3 {
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 0.1em;
}

h3 i {
  font-weight: normal;
  letter-spacing: 0;
  color: #6c7a89;
  text-transform: none;
  font-size: 16px;
}

h4 {
  font-size: 16px;
}

p,
em {
  font-size: 16px;
  line-height: 24px;
  color: #6c7a89;
}

a {
  color: #3fa3db;
  text-decoration: none;
}

hr {
  border: none;
  border-bottom: solid 1px #bdc3c7;
  margin: 20px 0;
}

.field {
  margin: 10px 0px;
  font-size: 16px;
}

.field .label {
  color: #8992a3;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.9em;
}

.field .value {
  color: #222b45;
  font-weight: bold;
}

.MuiChip-root {
  margin: 5px 5px 0 0;
}

/* action */
button.action-contained {
  background-color: #3fc380;
  color: #fff;
}
button.action-outlined {
  border-color: #3fc380;
  color: #3fc380;
}
/* primary */
button.primary-contained {
  background-color: #22313f;
  color: #fff;
}
button.primary-outlined {
  border-color: #22313f;
  color: #22313f;
}
/* secondary */
button.secondary-contained {
  background-color: #2574a9;
  color: #fff;
}
button.secondary-outlined {
  border-color: #2574a9;
  color: #2574a9;
}
/* cta */
button.cta-contained {
  background-color: #3fa3db;
  color: #fff;
}
button.cta-outlined {
  border-color: #3fa3db;
  color: #3fa3db;
}
.rdw-editor-wrapper {
  border: solid 1px #bdc3c7;
  border-radius: 5px;
  margin-bottom: 20px;
}
.rdw-editor-toolbar {
  border-radius: 0 !important;
  border: none !important;
  border-bottom: solid 1px #bdc3c7 !important;
  background: none !important;
}

.container-wrapper {
  padding: 25px;
}

.candidate-wrapper {
  max-width: 1150px;
  width: 100%;
  margin: 0 auto;
}

@media only screen and (max-width: 768px) {
  .container-wrapper {
    padding: 0px;
  }
}
